import React, { useState } from "react";
import { Navbar } from "./component/Navbar";
import ThemeContext from "./component/context/ThemeContxt";
import { Hero } from "./component/Hero";
function App() {
  const [isDark, setIsDark] = useState(false);
  const [swapAbout, setAbout] = useState(false);
  const [displayProject, setDisplayProject] = useState(false);
  const handleSwap = () => {
    setAbout(true);
  };
  const handleHome = () => {
    setAbout(false);
    setDisplayProject(false);
  };
  const handleProject = () => {
    setDisplayProject(true);
    setAbout(false);
  };
  return (
    <ThemeContext.Provider
      value={{
        isDark,
        setIsDark,
        swapAbout,
        setAbout,
        handleSwap,
        handleHome,
        handleProject,
        displayProject,
      }}
    >
      <div
        className={
          isDark
            ? "bg-slate-300 min-h-screen text-black duration-500"
            : "bg-gray-800 min-h-screen text-white duration-500"
        }
      >
        <Navbar />
        <Hero />
      </div>
    </ThemeContext.Provider>
  );
}

export default App;
