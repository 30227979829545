import React, { useContext, useState } from "react";
import { MdDarkMode, MdOutlineDarkMode } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import { RxCross2 } from "react-icons/rx";
import ThemeContext from "./context/ThemeContxt";
import { motion } from "framer-motion";
export const Navbar = () => {
  const { isDark, setIsDark } = useContext(ThemeContext);
  const [toggle, setToggle] = useState(false);
  const { handleSwap, handleHome, handleProject } = useContext(ThemeContext);
  const handContact = () => {
    window.location.href = "mailto:cheatchansovoth@gmail.com";
  };
  const handleOnClick = () => {
    setIsDark(!isDark);
  };
  const handleToggle = () => {
    setToggle(!toggle);
  };
  function switchChange() {
    return (
      <div
        className={
          isDark
            ? "flex w-20 h-10 bg-white  rounded-full duration-500 "
            : "flex w-20 h-10 bg-gray-600 rounded-full duration-500 "
        }
      >
        <span
          className={
            isDark
              ? "h-10 w-10  bg-slate-400 rounded-full flex  justify-center items-center ml-10 duration-500"
              : "h-10 w-10  bg-gray-700 rounded-full flex justify-center items-center duration-500"
          }
        >
          <p
            onClick={handleOnClick}
            className={isDark ? "text-2xl  text-black" : "text-2xl text-white"}
          >
            {isDark ? <MdDarkMode /> : <MdOutlineDarkMode />}
          </p>
        </span>
      </div>
    );
  }
  function Navbar() {
    return (
      <div className="w-3/4 mx-auto p-3 lg:flex justify-between hidden">
        <div>{switchChange()}</div>
        <div
          className={
            isDark
              ? "flex space-x-20 bg-slate-400 rounded-full p-3 px-6 "
              : "flex space-x-20 bg-gray-700 rounded-full p-3 px-6 "
          }
        >
          <p
            className="font-semibold hover:text-slate-400 duration-300 cursor-pointer"
            onClick={() => handleHome()}
          >
            Home
          </p>
          <p
            className="font-semibold hover:text-slate-400 duration-300 cursor-pointer"
            onClick={() => handleSwap()}
          >
            About
          </p>
          <p
            className="font-semibold hover:text-slate-400 duration-300 cursor-pointer"
            onClick={() => handleProject()}
          >
            Project
          </p>
        </div>
        <div
          className={
            isDark
              ? "p-3 px-5 rounded-full border-2 bg-slate-500 duration-500 "
              : "p-3 px-5 rounded-full border-2 bg-gradient-to-r hover:from-slate-400 hover:to-gray-700"
          }
        >
          <p
            className="font-semibold hover:text-slate-400 duration-300 cursor-pointer"
            onClick={() => handContact()}
          >
            Contact
          </p>
        </div>
      </div>
    );
  }
  function responsiveNavbar() {
    return (
      <div className="w-full lg:hidden py-3">
        <div className="w-[80%] flex mx-auto justify-between items-center">
          <div>{switchChange()}</div>
          <div className="">
            <motion.span
              onClick={handleToggle}
              animate={{ rotate: toggle ? 45 : 0 }}
              transition={{ duration: 0.5 }}
            >
              {toggle ? <RxCross2 /> : <GiHamburgerMenu />}
            </motion.span>
          </div>
        </div>
        {toggle && (
          <motion.div
            key="my-component"
            className="w-3/4 min-h-[20vh] mx-auto my-3 bg-slate-700 flex rounded-3xl"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div className="w-[100%] flex flex-col justify-center items-center space-y-3">
              <p
                className="font-semibold hover:text-slate-400 duration-300 cursor-pointer"
                onClick={() => handleHome()}
              >
                Home
              </p>
              <p
                className="font-semibold hover:text-slate-400 duration-300 cursor-pointer"
                onClick={() => handleSwap()}
              >
                About
              </p>
              <p
                className="font-semibold hover:text-slate-400 duration-300 cursor-pointer"
                onClick={() => handleProject()}
              >
                Project
              </p>
              <p
                className="bg-slate-500 rounded-2xl p-2 px-4 font-semibold hover:text-slate-400 duration-300 cursor-pointer"
                onClick={() => handContact()}
              >
                Contact
              </p>
            </div>
          </motion.div>
        )}
      </div>
    );
  }
  return (
    <div>
      <div>{Navbar()}</div>
      <div>{responsiveNavbar()}</div>
    </div>
  );
};
