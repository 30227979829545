import React, { useContext, useState } from "react";
import profile from "./assets/IMG_5412.PNG";
import { AiOutlineArrowRight } from "react-icons/ai";
import { motion } from "framer-motion";
import p1 from "./assets/p1.jpg";
import p2 from "./assets/p2.jpg";
import p3 from "./assets/p3.jpg";
import p4 from "./assets/p4.jpg";
import project1 from "./assets/project1.png";
import project2 from "./assets/project2.png";
import project3 from "./assets/project3.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ThemeContext from "./context/ThemeContxt";

export const Hero = () => {
  const { swapAbout } = useContext(ThemeContext);
  const { displayProject } = useContext(ThemeContext);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const handleOnClick = (url) => {
    window.location.href = url;
  };
  return (
    <div className="min-h-[80vh] flex justify-center items-center">
      <div class="lg:w-[80%] grid grid-cols-3 grid-rows-3 gap-4 mx-auto">
        <motion.div
          class="bg-slate-500 col-span-3 xl:col-span-2 row-span-3 rounded-2xl p-5"
          animate={{ x: 0, rotateY: 0 }}
          initial={{ rotateY: -90 }}
          transition={{ duration: 0.8, ease: "easeInOut" }}
        >
          <div className="flex flex-col max-w-[720px] min-h-[50vh] mx-auto justify-center">
            <div className="w-[30%]">
              <img src={profile} alt="profile"></img>
            </div>
            {swapAbout ? (
              <div className="space-y-5">
                <h1 className="text-3xl font-semibold ">Hello! My name is </h1>
                <h1 className="text-6xl font-bold">Chansovoth Cheat</h1>
                <p className="mx-auto font-semibold">
                  As an entry-level software development student, I am
                  passionate about creating websites that not only look great
                  but also provide a seamless user experience. My focus on
                  self-learning has allowed me to gain expertise in Bootstrap,
                  Tailwind CSS, React.js, HTML, and CSS.
                  <br /> While I may be new to the industry, I am highly
                  motivated and eager to learn from experienced professionals. I
                  have completed several projects in my studies that have helped
                  me to hone my skills and gain valuable experience. I am always
                  looking for new opportunities to learn and grow as a software
                  developer.
                </p>
              </div>
            ) : (
              <div className="">
                <h1 className="text-3xl font-semibold ">Hello! My name is </h1>
                <h1 className="text-6xl font-bold">Chansovoth Cheat</h1>
                <p className=" mx-auto font-semibold">
                  I'm a final year student majoring in Software Development, and
                  I'm passionate about web development. While pursuing my
                  degree, I have also been self-teaching myself web development
                  skills such as HTML, CSS, Bootstrap, React.js, and
                  Tailwindcss.
                </p>
              </div>
            )}
          </div>
          <motion.div
            className={
              displayProject
                ? "grid xl:grid-cols-3 grid-cols-2 xl:w-[80%] min-h-[30vh] xl:min-h-[10vh] gap-4 mx-auto my-5 pointer-events-none opacity-50 duration-1000"
                : "grid xl:grid-cols-3 grid-cols-2 xl:w-[80%] min-h-[30vh] xl:min-h-[10vh] gap-4 mx-auto my-5 duration-1000"
            }
            animate={{ x: 0, rotateY: 0 }}
            initial={{ rotateY: -90 }}
            transition={{ duration: 0.2, ease: "easeInOut", delay: 0.1 }}
          >
            <div
              className="bg-red-500 bg-cover bg-center rounded-xl relative"
              style={{
                backgroundImage:
                  "url('https://i2-prod.mirror.co.uk/incoming/article676858.ece/ALTERNATES/s1200/Topic%20-%20Facebook')",
              }}
            >
              <p className="absolute bottom-1 end-0 p-1 right-3 text-xl text-black">
                <AiOutlineArrowRight
                  className="cursor-pointer hover:scale-125 bg-slate-400 rounded-full"
                  onClick={() =>
                    handleOnClick("https://www.facebook.com/wwwwwk3n")
                  }
                />
              </p>
            </div>
            <div
              className="bg-red-500 bg-cover bg-center rounded-xl relative"
              style={{
                backgroundImage:
                  "url('https://thurrott.s3.amazonaws.com/wp-content/uploads/sites/2/2023/01/GitHub.jpeg')",
              }}
            >
              <p className="absolute  bottom-1 end-0 p-1 right-3 text-xl text-black ">
                <AiOutlineArrowRight
                  className="cursor-pointer hover:scale-125 bg-slate-400 rounded-full"
                  onClick={() =>
                    handleOnClick("https://github.com/cheatchansovoth")
                  }
                />
              </p>
            </div>
            <div
              className="bg-red-500 bg-cover bg-center  rounded-xl relative"
              style={{
                backgroundImage:
                  "url('https://cdn.lynda.com/course/496940/496940-1593629650385-16x9.jpg')",
              }}
            >
              <p className="absolute bottom-1 end-0 p-1 right-3 text-xl text-black bg-slate-400 rounded-full">
                <AiOutlineArrowRight
                  className="cursor-pointer hover:scale-125"
                  onClick={() =>
                    handleOnClick(
                      "https://www.linkedin.com/in/chansovothcheat/"
                    )
                  }
                />
              </p>
            </div>
          </motion.div>
          <motion.div
            className={
              swapAbout
                ? "grid xl:grid-cols-3 grid-cols-2 xl:w-[80%] min-h-[30vh] xl:min-h-[15vh] gap-4 mx-auto my-5 pointer-events-none opacity-50 duration-1000"
                : "grid xl:grid-cols-3 grid-cols-2 xl:w-[80%] min-h-[30vh] xl:min-h-[15vh] gap-4 mx-auto my-5 duration-1000"
            }
            animate={{ x: 0, rotateY: 0 }}
            initial={{ rotateY: -90 }}
            transition={{ duration: 0.2, ease: "easeInOut", delay: 0.2 }}
          >
            <div
              className="bg-gray-500 bg-cover bg-center rounded-xl relative flex justify-center items-center "
              style={{
                backgroundImage: `url(${project1})`,
              }}
            >
              <p className="absolute bottom-1 end-0 p-1 right-3 text-xl text-black">
                <AiOutlineArrowRight
                  className="cursor-pointer hover:scale-125 bg-slate-400 rounded-full "
                  onClick={() =>
                    handleOnClick("https://netflux-bay.vercel.app/")
                  }
                />
              </p>
              <button
                className="btn btn-outline"
                onClick={() => {
                  handleOnClick("https://github.com/cheatchansovoth/netflux");
                }}
              >
                <span>&lt;Code /&gt;</span>
              </button>
            </div>
            <div
              className="bg-gray-500 bg-cover bg-center rounded-xl relative flex justify-center items-center "
              style={{
                backgroundImage: `url(${project2})`,
              }}
            >
              <p className="absolute bottom-1 end-0 p-1 right-3 text-xl text-black">
                <AiOutlineArrowRight
                  className="cursor-pointer hover:scale-125 bg-slate-400 rounded-full "
                  onClick={() => handleOnClick("https://clockson.vercel.app/")}
                />
              </p>
              <button
                className="btn btn-outline"
                onClick={() =>
                  handleOnClick("https://github.com/cheatchansovoth/Clockson")
                }
              >
                <span>&lt;Code /&gt;</span>
              </button>
            </div>
            <div
              className="bg-gray-500 bg-cover bg-center rounded-xl relative flex justify-center items-center "
              style={{
                backgroundImage: `url(${project3})`,
              }}
            >
              <p className="absolute bottom-1 end-0 p-1 right-3 text-xl text-black">
                <AiOutlineArrowRight
                  className="cursor-pointer hover:scale-125 bg-slate-400 rounded-full "
                  onClick={() =>
                    handleOnClick("https://car-insurance-zeta.vercel.app/")
                  }
                />
              </p>
              <button
                className="btn btn-outline"
                onClick={() =>
                  handleOnClick(
                    "https://github.com/cheatchansovoth/car-insurance"
                  )
                }
              >
                <span>&lt;Code /&gt;</span>
              </button>
            </div>
          </motion.div>
        </motion.div>
        <motion.div
          class="bg-slate-500 col-span-3 xl:col-span-1 row-span-2 rounded-2xl"
          animate={{ x: 0, rotateY: 0 }}
          initial={{ rotateY: -90 }}
          transition={{ duration: 1, ease: "easeInOut", delay: 0.3 }}
        >
          <div className="flex justify-center items-center">
            <h1 className="text-3xl font-semibold">Technology</h1>
          </div>
          <div className="">
            <div className="border-b-4 flex mx-3">
              <img
                src="https://www.vectorlogo.zone/logos/w3_html5/w3_html5-icon.svg"
                alt="html"
                width="40"
                height="40"
              />
              <p className="text-xl font-semibold p-3">HTML</p>
            </div>
            <div className="border-b-4 flex mx-3">
              <img
                src="https://www.vectorlogo.zone/logos/w3_css/w3_css-icon.svg"
                alt="css"
                width="40"
                height="40"
              />
              <p className="text-xl font-semibold p-3">CSS</p>
            </div>
            <div className="border-b-4 flex mx-3">
              <img
                src="https://www.vectorlogo.zone/logos/getbootstrap/getbootstrap-icon.svg"
                alt="bootstrap"
                width="40"
                height="40"
              />
              <p className="text-xl font-semibold p-3">Bootstrap</p>
            </div>
            <div className="border-b-4 flex mx-3">
              <img
                src="https://www.vectorlogo.zone/logos/tailwindcss/tailwindcss-icon.svg"
                alt="tailwindcss"
                width="40"
                height="40"
              />
              <p className="text-xl font-semibold p-3">Tailwindcss</p>
            </div>
            <div className="border-b-4 flex mx-3">
              <img
                src="https://www.vectorlogo.zone/logos/reactjs/reactjs-icon.svg"
                alt="react.js"
                width="40"
                height="40"
              />
              <p className="text-xl font-semibold p-3">React.js</p>
            </div>
            <div className="border-b-4 flex mx-3">
              <img
                src="https://cdn-icons-png.flaticon.com/512/4299/4299956.png"
                alt="sql"
                width="40"
                height="40"
              />
              <p className="text-xl font-semibold p-3">SQL</p>
            </div>
            <div className="border-b-4 flex mx-3">
              <img
                src="https://www.vectorlogo.zone/logos/nodejs/nodejs-icon.svg"
                alt="node.js"
                width="40"
                height="40"
              />
              <p className="text-xl font-semibold p-3">Node.js (Entry level)</p>
            </div>
            <div className=" flex mx-3">
              <img
                src="https://icons.veryicon.com/png/o/miscellaneous/gwidc_2/flexible-deployment.png"
                alt="cloud"
                width="40"
                height="40"
              />
              <p className="text-xl font-semibold p-3">Deployment</p>
            </div>
          </div>
        </motion.div>
        <motion.div
          class="col-span-3 xl:col-span-1 row-span-1 "
          animate={{ x: 0, rotateY: 0 }}
          initial={{ rotateY: -90 }}
          transition={{ duration: 1, ease: "easeInOut", delay: 0.1 }}
        >
          <Carousel
            responsive={responsive}
            removeArrowOnDeviceType={["tablet", "mobile"]}
            showDots={true}
            className="rounded-2xl"
          >
            <div className=" relative ">
              <img
                src={p1}
                alt="p1"
                className="h-[280px]  w-full object-cover"
              ></img>
            </div>
            <div className=" relative">
              <img
                src={p2}
                alt="p2"
                className="h-[280px]  w-full object-cover"
              ></img>
            </div>
            <div className=" relative">
              <img
                src={p3}
                alt="p3"
                className="h-[280px] w-full object-cover bg-top"
              ></img>
            </div>
            <div className="relative">
              <img
                src={p4}
                alt="p4"
                className="h-[280px] w-full object-cover"
              ></img>
            </div>
          </Carousel>
        </motion.div>
      </div>
    </div>
  );
};
